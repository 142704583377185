<template>
	<div>
		<ContentHeader title="User &amp; Hak Akses" url="/user-dan-hak-akses" subTitle="Tambah User &amp; Hak Akses" />
		<LoadingScreen v-if="loading" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="card">
					<div class="card-body">
						<!-- HAK AKSES -->
						<div class="row">
							<div class="col-lg-1"></div>
							<div class="col-lg-10">
								<form @submit.prevent="prosesData">
									<div class="row">
										<div class="col">
											<div class="icheck-primary">
												<input type="checkbox">
												<h5>Dashboard</h5>
											</div>
											<div class="form-group clearfix ml-2" v-for="(menu, index) in hak_akses.filter((a) => a.menu_id == 1)" :key="index">
												<div class="icheck-primary d-inline">
													<input type="checkbox" :id="menu.nama" v-model="menu.status">
													<label :for="menu.nama" class="text-sm">
														{{ menu.deskripsi }}
													</label>
												</div>
											</div>
										</div>
										<div class="col"></div>
										<div class="col"></div>
									</div>
									<div class="row">
										<div class="col">
											<h5>Master Data</h5>
											<div class="form-group clearfix ml-2" v-for="(menu, index) in hak_akses.filter((a) => a.menu_id == 2)" :key="index">
												<div class="icheck-primary d-inline">
													<input type="checkbox" :id="menu.nama" v-model="menu.status">
													<label :for="menu.nama" class="text-sm">
														{{ menu.deskripsi }}
													</label>
												</div>
											</div>
										</div>
										<div class="col">
											<h5>Rekam Medis</h5>
											<div class="form-group clearfix ml-2" v-for="(menu, index) in hak_akses.filter((a) => a.menu_id == 3)" :key="index">
												<div class="icheck-primary d-inline">
													<input type="checkbox" :id="menu.nama" v-model="menu.status">
													<label :for="menu.nama" class="text-sm">
														{{ menu.deskripsi }}
													</label>
												</div>
											</div>
										</div>
										<div class="col">
											<h5>Farmasi / Inventori</h5>
											<div class="form-group clearfix ml-2" v-for="(menu, index) in hak_akses.filter((a) => a.menu_id == 4)" :key="index">
												<div class="icheck-primary d-inline">
													<input type="checkbox" :id="menu.nama" v-model="menu.status">
													<label :for="menu.nama" class="text-sm">
														{{ menu.deskripsi }}
													</label>
												</div>
											</div>
										</div>
									</div>
									<div class="row mt-2">
										<div class="col">
											<h5>Akuntansi</h5>
											<div class="form-group clearfix ml-2" v-for="(menu, index) in hak_akses.filter((a) => a.menu_id == 5)" :key="index">
												<div class="icheck-primary d-inline">
													<input type="checkbox" :id="menu.nama" v-model="menu.status">
													<label :for="menu.nama" class="text-sm">
														{{ menu.deskripsi }}
													</label>
												</div>
											</div>
										</div>
										<div class="col">
											<h5>HRD</h5>
											<div class="form-group clearfix ml-2" v-for="(menu, index) in hak_akses.filter((a) => a.menu_id == 6)" :key="index">
												<div class="icheck-primary d-inline">
													<input type="checkbox" :id="menu.nama" v-model="menu.status">
													<label :for="menu.nama" class="text-sm">
														{{ menu.deskripsi }}
													</label>
												</div>
											</div>
										</div>
										<div class="col">
											<h5>Pelayanan</h5>
											<div class="form-group clearfix ml-2" v-for="(menu, index) in hak_akses.filter((a) => a.menu_id == 7)" :key="index">
												<div class="icheck-primary d-inline">
													<input type="checkbox" :id="menu.nama" v-model="menu.status">
													<label :for="menu.nama" class="text-sm">
														{{ menu.deskripsi }}
													</label>
												</div>
											</div>
										</div>
									</div>	
									<div class="row">
										<div class="col">
											<h5>Laporan-laporan</h5>
											<div class="form-group clearfix ml-2" v-for="(menu, index) in hak_akses.filter((a) => a.menu_id == 8)" :key="index">
												<div class="icheck-primary d-inline">
													<input type="checkbox" :id="menu.nama" v-model="menu.status">
													<label :for="menu.nama" class="text-sm">
														{{ menu.deskripsi }}
													</label>
												</div>
											</div>
										</div>
										<div class="col">
											<h5>Analisis Statistik</h5>
											<div class="form-group clearfix ml-2" v-for="(menu, index) in hak_akses.filter((a) => a.menu_id == 9)" :key="index">
												<div class="icheck-primary d-inline">
													<input type="checkbox" :id="menu.nama" v-model="menu.status">
													<label :for="menu.nama" class="text-sm">
														{{ menu.deskripsi }}
													</label>
												</div>
											</div>
										</div>
										<div class="col">
											<h5>Marketing</h5>
										</div>
									</div>
									<div class="d-flex mt-4">
										<button type="submit" class="btn btn-primary d-flex align-items-center ml-2">
											<div>Submit</div>	
											<div class="spinner-border spinner-border-sm ml-2" v-if="loading" role="status">
											<span class="sr-only">Loading...</span>
											</div>
										</button>
									</div>
								</form>
							</div>
							<div class="col-lg-1"></div>
						</div>
						
						<!-- END HAK AKSES -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import ContentHeader from '@/components/ContentHeader2'
import LoadingScreen from '@/components/LoadingScreen'

import { ref, onMounted } from 'vue'
// import store from '@/store'
import axios from 'axios'
import Swal from 'sweetalert2'
import router from '@/router'
import { useRoute } from 'vue-router'
import { hak_akses } from '@/entities/menu'

const route = useRoute()

const loading = ref(false)

// HAK AKSES
// const hak_akses  = ref(menu)
console.log(hak_akses.filter((a) => a.menu_id == 1))

const getDataResult = async() => {
	loading.value = true
	let response = await axios.get(`api/hak_akses/getWhereUser/${route.params.id}`)

	if (response.data != 'kosong') {
		for (let i = 0; i < hak_akses.length; i++) {
			for (let j = 0; j < response.data.length; j++) {
				if (hak_akses[i].nama == response.data[j].nama) {
					hak_akses[i].status = true
					j = response.data.length
				}
			}
		}
	}
	loading.value = false
}

const prosesData = () => {
	loading.value = true
	let data = {
		'hak_akses' : hak_akses,
		'jumlah' : hak_akses.length
	}
	axios.put(`api/hak_akses/update/${route.params.id}`, data)
	.then(() => {
		Swal.fire({
			title: 'Berhasil!',
			text: 'Berhasil Mengedit Data',
			icon: 'success',
			showConfirmButton: false,
			timer: 1500
		})	
		loading.value = false
		router.push('/user-dan-hak-akses')
	})
	.catch(() => {
		Swal.fire({
			title: 'Gagal!',
			text: 'Gagal Mengedit Data',
			icon: 'error',
			confirmButtonText: 'Ok'
		})	
		loading.value = false
	})
}

onMounted(() => {
	getDataResult()
})

</script>

<style type="text/css" src="@/assets/bs-stepper.min.css"></style>