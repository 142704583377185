export const hak_akses = [
    {
        'menu_id' : 1,
        'sub_menu_id' : 0,
        'nama' : 'pendapatan_harian',
        'deskripsi' : 'Pendapatan Harian',
        'status' : false
    },
    {
        'menu_id' : 1,
        'sub_menu_id' : 0,
        'nama' : 'pengeluaran_harian',
        'deskripsi' : 'Pengeluaran Harian',
        'status' : false
    },
    {
        'menu_id' : 1,
        'sub_menu_id' : 0,
        'nama' : 'kunjungan_pasien',
        'deskripsi' : 'Kunjungan Pasien',
        'status' : false
    },
    {
        'menu_id' : 1,
        'sub_menu_id' : 0,
        'nama' : 'kunjungan_pasien_baru',
        'deskripsi' : 'Kunjungan Pasien Baru',
        'status' : false
    },
    {
        'menu_id' : 1,
        'sub_menu_id' : 0,
        'nama' : 'grafik_pendapatan',
        'deskripsi' : 'Grafik Pendapatan',
        'status' : false
    },
    {
        'menu_id' : 1,
        'sub_menu_id' : 0,
        'nama' : 'grafik_kunjungan',
        'deskripsi' : 'Grafik Kunjungan',
        'status' : false
    },
    {
        'menu_id' : 1,
        'sub_menu_id' : 0,
        'nama' : 'pendapatan_poli',
        'deskripsi' : 'Pendapatan Poli',
        'status' : false
    },    
    {
        'menu_id' : 2,
        'sub_menu_id' : 0,
        'nama' : 'user_hak_akses',
        'deskripsi' : 'User & Hak Akses',
        'status' : false,
    },
    {
        'menu_id' : 2,
        'sub_menu_id' : 0,
        'nama' : 'setting_klinik',
        'deskripsi' : 'Setting Klinik',
        'status' : false,
    },
    {
        'menu_id' : 2,
        'sub_menu_id' : 0,
        'nama' : 'setup_cabang',
        'deskripsi' : 'Setup Cabang',
        'status' : false,
    },
    {
        'menu_id' : 2,
        'sub_menu_id' : 0,
        'nama' : 'data_tindakan',
        'deskripsi' : 'Data Tindakan',
        'status' : false,
    },
    {
        'menu_id' : 2,
        'sub_menu_id' : 0,
        'nama' : 'administrasi_pemeriksaan',
        'deskripsi' : 'Administrasi & Pemeriksaan',
        'status' : false,
    },
    {
        'menu_id' : 2,
        'sub_menu_id' : 0,
        'nama' : 'poli',
        'deskripsi' : 'Poli',
        'status' : false,
    },
    {
        'menu_id' : 2,
        'sub_menu_id' : 0,
        'nama' : 'layanan',
        'deskripsi' : 'Layanan',
        'status' : false,
    },
    {
        'menu_id' : 2,
        'sub_menu_id' : 0,
        'nama' : 'jadwal_shift',
        'deskripsi' : 'Jadwal Shift',
        'status' : false,
    },
    {
        'menu_id' : 2,
        'sub_menu_id' : 0,
        'nama' : 'pergantian_shift',
        'deskripsi' : 'Pergantian Shift',
        'status' : false,
    },
    {
        'menu_id' : 2,
        'sub_menu_id' : 0,
        'nama' : 'laporan_pergantian_shift',
        'deskripsi' : 'Laporan Pergantian Shift',
        'status' : false,
    },    
    {
        'menu_id' : 3,
        'sub_menu_id' : 0,
        'nama' : 'data_pasien',
        'deskripsi' : 'Data Pasien',
        'status' : false,
    },
    {
        'menu_id' : 3,
        'sub_menu_id' : 0,
        'nama' : 'rekam_medis_pasien',
        'deskripsi' : 'Rekam Medis Pasien',
        'status' : false,
    },
    {
        'menu_id' : 3,
        'sub_menu_id' : 0,
        'nama' : 'mapping_wilayah',
        'deskripsi' : 'Mapping Wilayah',
        'status' : false,
    },    
    {
        'menu_id' : 4,
        'sub_menu_id' : 0,
        'nama' : 'data_obat',
        'deskripsi' : 'Data Obat',
        'status' : false,
    },
    {
        'menu_id' : 4,
        'sub_menu_id' : 0,
        'nama' : 'data_percetakan',
        'deskripsi' : 'Data Pemeriksaan',
        'status' : false,
    },
    {
        'menu_id' : 4,
        'sub_menu_id' : 0,
        'nama' : 'data_alat_kesehatan',
        'deskripsi' : 'Data Alat Kesehatan',
        'status' : false,
    },
    {
        'menu_id' : 4,
        'sub_menu_id' : 0,
        'nama' : 'data_detail_produk',
        'deskripsi' : 'Data Detail Produk',
        'status' : false,
    },
    {
        'menu_id' : 4,
        'sub_menu_id' : 0,
        'nama' : 'pembelian_obat',
        'deskripsi' : 'Pembelian Obat',
        'status' : false,
    },
    {
        'menu_id' : 4,
        'sub_menu_id' : 0,
        'nama' : 'pemesanan_obat',
        'deskripsi' : 'Pemesanan Obat',
        'status' : false,
    },
    {
        'menu_id' : 4,
        'sub_menu_id' : 0,
        'nama' : 'penjualan_obat',
        'deskripsi' : 'Penjualan Obat',
        'status' : false,
    },
    {
        'menu_id' : 4,
        'sub_menu_id' : 0,
        'nama' : 'penjualan_langsung',
        'deskripsi' : 'Penjualan Langsung',
        'status' : false,
    },
    {
        'menu_id' : 4,
        'sub_menu_id' : 0,
        'nama' : 'stok_produk',
        'deskripsi' : 'Stok Produk',
        'status' : false,
    },
    {
        'menu_id' : 4,
        'sub_menu_id' : 0,
        'nama' : 'stok_opname',
        'deskripsi' : 'Stok Opname',
        'status' : false,
    },
    {
        'menu_id' : 4,
        'sub_menu_id' : 0,
        'nama' : 'kartu_stok',
        'deskripsi' : 'Kartu Stok',
        'status' : false,
    },
    {
        'menu_id' : 4,
        'sub_menu_id' : 0,
        'nama' : 'data_supplier',
        'deskripsi' : 'Data Supplier',
        'status' : false,
    },
    {
        'menu_id' : 4,
        'sub_menu_id' : 0,
        'nama' : 'data_pelanggan',
        'deskripsi' : 'Data Pelanggan',
        'status' : false,
    },
    {
        'menu_id' : 4,
        'sub_menu_id' : 0,
        'nama' : 'racikan_obat',
        'deskripsi' : 'Racikan Obat',
        'status' : false,
    },
    {
        'menu_id' : 4,
        'sub_menu_id' : 0,
        'nama' : 'lokasi_obat',
        'deskripsi' : 'Lokasi Obat',
        'status' : false,
    },    
    {
        'menu_id' : 5,
        'sub_menu_id' : 0,
        'nama' : 'data_akun_keuangan',
        'deskripsi' : 'Data Akun Keuangan',
        'status' : false,
    },
    {
        'menu_id' : 5,
        'sub_menu_id' : 0,
        'nama' : 'data_jurnal_keuangan',
        'deskripsi' : 'Data Jurnal Keuangan',
        'status' : false,
    },
    {
        'menu_id' : 5,
        'sub_menu_id' : 0,
        'nama' : 'data_jurnal_singkat',
        'deskripsi' : 'Data Jurnal Singkat',
        'status' : false,
    },
    {
        'menu_id' : 5,
        'sub_menu_id' : 0,
        'nama' : 'data_budgeting_akun_keuangan',
        'deskripsi' : 'Data Budgeting Akun Keuangan',
        'status' : false,
    },    
    {
        'menu_id' : 6,
        'sub_menu_id' : 0,
        'nama' : 'data_karyawan',
        'deskripsi' : 'Data Karyawan',
        'status' : false,
    },
    {
        'menu_id' : 6,
        'sub_menu_id' : 0,
        'nama' : 'data_absensi_karyawan',
        'deskripsi' : 'Data Absensi Karyawan',
        'status' : false,
    },    
    {
        'menu_id' : 7,
        'sub_menu_id' : 0,
        'nama' : 'resepsionis',
        'deskripsi' : 'Resepsionis',
        'status' : false,
    },
    {
        'menu_id' : 7,
        'sub_menu_id' : 0,
        'nama' : 'perawatan_dokter',
        'deskripsi' : 'Perawatan Dokter',
        'status' : false,
    },
    {
        'menu_id' : 7,
        'sub_menu_id' : 0,
        'nama' : 'farmasi',
        'deskripsi' : 'Farmasi',
        'status' : false,
    },
    {
        'menu_id' : 7,
        'sub_menu_id' : 0,
        'nama' : 'farmasi_penjualan_langsung',
        'deskripsi' : 'Farmasi Penjualan Langsung',
        'status' : false,
    },
    {
        'menu_id' : 7,
        'sub_menu_id' : 0,
        'nama' : 'kasir',
        'deskripsi' : 'Kasir',
        'status' : false,
    },
    {
        'menu_id' : 7,
        'sub_menu_id' : 0,
        'nama' : 'data_transaksi_perawatan',
        'deskripsi' : 'Data Transaksi Perawatan',
        'status' : false,
    },    
    {
        'menu_id' : 8,
        'sub_menu_id' : 0,
        'nama' : 'laporan_pelayanan_klinik',
        'deskripsi' : 'Laporan Pelayana Klinik',
        'status' : false,
    },
    {
        'menu_id' : 8,
        'sub_menu_id' : 0,
        'nama' : 'laporan_rekam_medis_pasien',
        'deskripsi' : 'Laporan Rekam Medis Pasien',
        'status' : false,
    },
    {
        'menu_id' : 8,
        'sub_menu_id' : 0,
        'nama' : 'laporan_farmasi',
        'deskripsi' : 'Laporan Farmasi',
        'status' : false,
    },
    {
        'menu_id' : 8,
        'sub_menu_id' : 0,
        'nama' : 'laporan_akuntansi',
        'deskripsi' : 'Laporan Akuntansi',
        'status' : false,
    },
    {
        'menu_id' : 8,
        'sub_menu_id' : 0,
        'nama' : 'laporan_hrd',
        'deskripsi' : 'Laporan HRD',
        'status' : false,
    },
    {
        'menu_id' : 8,
        'sub_menu_id' : 0,
        'nama' : 'laporan_jasa_medis',
        'deskripsi' : 'Lapran Jasa Medis',
        'status' : false,
    },    
    {
        'menu_id' : 9,
        'sub_menu_id' : 0,
        'nama' : 'traffic',
        'deskripsi' : 'Traffic',
        'status' : false,
    },
    {
        'menu_id' : 9,
        'sub_menu_id' : 0,
        'nama' : 'revenue',
        'deskripsi' : 'Revenue',
        'status' : false,
    },
    {
        'menu_id' : 9,
        'sub_menu_id' : 0,
        'nama' : 'demografi',
        'deskripsi' : 'Demografi',
        'status' : false,
    },
]

export const menu = {
    'dashboard' : {
        'pendapatan_harian' : {
            'menu_id' : 1,
            'sub_menu_id' : 0,
            'nama' : 'pendapatan_harian',
            'deskripsi' : 'Pendapatan Harian',
            'status' : false
        },
        'pengeluaran_harian' : {
            'menu_id' : 1,
            'sub_menu_id' : 0,
            'nama' : 'pengeluaran_harian',
            'deskripsi' : 'Pengeluaran Harian',
            'status' : false
        },
        'kunjungan_pasien' : {
            'menu_id' : 1,
            'sub_menu_id' : 0,
            'nama' : 'kunjungan_pasien',
            'deskripsi' : 'Kunjungan Pasien',
            'status' : false
        },
        'kunjungan_pasien_baru' : {
            'menu_id' : 1,
            'sub_menu_id' : 0,
            'nama' : 'kunjungan_pasien_baru',
            'deskripsi' : 'Kunjungan Pasien Baru',
            'status' : false
        },
        'grafik_pendapatan' : {
            'menu_id' : 1,
            'sub_menu_id' : 0,
            'nama' : 'grafik_pendapatan',
            'deskripsi' : 'Grafik Pendapatan',
            'status' : false
        },
        'grafik_kunjungan' : {
            'menu_id' : 1,
            'sub_menu_id' : 0,
            'nama' : 'grafik_kunjungan',
            'deskripsi' : 'Grafik Kunjungan',
            'status' : false
        },
        'pendapatan_poli' : {
            'menu_id' : 1,
            'sub_menu_id' : 0,
            'nama' : 'pendapatan_poli',
            'deskripsi' : 'Pendapatan Poli',
            'status' : false
        },
    },
    'master' : {
        'user_hak_akses' : {
            'menu_id' : 2,
            'sub_menu_id' : 0,
            'nama' : 'user_hak_akses',
            'deskripsi' : 'User & Hak Akses',
            'status' : false,
        },
        'setting_klinik' : {
            'menu_id' : 2,
            'sub_menu_id' : 0,
            'nama' : 'setting_klinik',
            'deskripsi' : 'Setting Klinik',
            'status' : false,
        },
        'setup_cabang' : {
            'menu_id' : 2,
            'sub_menu_id' : 0,
            'nama' : 'setup_cabang',
            'deskripsi' : 'Setup Cabang',
            'status' : false,
        },
        'data_tindakan' : {
            'menu_id' : 2,
            'sub_menu_id' : 0,
            'nama' : 'data_tindakan',
            'deskripsi' : 'Data Tindakan',
            'status' : false,
        },
        'administrasi_pemeriksaan' : {
            'menu_id' : 2,
            'sub_menu_id' : 0,
            'nama' : 'administrasi_pemeriksaan',
            'deskripsi' : 'Administrasi & Pemeriksaan',
            'status' : false,
        },
        'poli' : {
            'menu_id' : 2,
            'sub_menu_id' : 0,
            'nama' : 'poli',
            'deskripsi' : 'Poli',
            'status' : false,
        },
        'layanan' : {
            'menu_id' : 2,
            'sub_menu_id' : 0,
            'nama' : 'layanan',
            'deskripsi' : 'Layanan',
            'status' : false,
        },
        'jadwal_shift' : {
            'menu_id' : 2,
            'sub_menu_id' : 0,
            'nama' : 'jadwal_shift',
            'deskripsi' : 'Jadwal Shift',
            'status' : false,
        },
        'pergantian_shift' : {
            'menu_id' : 2,
            'sub_menu_id' : 0,
            'nama' : 'pergantian_shift',
            'deskripsi' : 'Pergantian Shift',
            'status' : false,
        },
        'laporan_pergantian_shift' : {
            'menu_id' : 2,
            'sub_menu_id' : 0,
            'nama' : 'laporan_pergantian_shift',
            'deskripsi' : 'Laporan Pergantian Shift',
            'status' : false,
        },
    },
    'rekam_medis' : {
        'data_pasien' : {
            'menu_id' : 3,
            'sub_menu_id' : 0,
            'nama' : 'data_pasien',
            'deskripsi' : 'Data Pasien',
            'status' : false,
        },
        'rekam_medis_pasien' : {
            'menu_id' : 3,
            'sub_menu_id' : 0,
            'nama' : 'rekam_medis_pasien',
            'deskripsi' : 'Rekam Medis Pasien',
            'status' : false,
        },
        'mapping_wilayah' : {
            'menu_id' : 3,
            'sub_menu_id' : 0,
            'nama' : 'mapping_wilayah',
            'deskripsi' : 'Mapping Wilayah',
            'status' : false,
        },
    },
    'farmasi' : {
        'data_obat' : {
            'menu_id' : 4,
            'sub_menu_id' : 0,
            'nama' : 'data_obat',
            'deskripsi' : 'Data Obat',
            'status' : false,
        },
        'data_percetakan' : {
            'menu_id' : 4,
            'sub_menu_id' : 0,
            'nama' : 'data_percetakan',
            'deskripsi' : 'Data Pemeriksaan',
            'status' : false,
        },
        'data_alat_kesehatan' : {
            'menu_id' : 4,
            'sub_menu_id' : 0,
            'nama' : 'data_alat_kesehatan',
            'deskripsi' : 'Data Alat Kesehatan',
            'status' : false,
        },
        'data_detail_produk' : {
            'menu_id' : 4,
            'sub_menu_id' : 0,
            'nama' : 'data_detail_produk',
            'deskripsi' : 'Data Detail Produk',
            'status' : false,
        },
        'pembelian_obat' : {
            'menu_id' : 4,
            'sub_menu_id' : 0,
            'nama' : 'pembelian_obat',
            'deskripsi' : 'Pembelian Obat',
            'status' : false,
        },
        'pemesanan_obat' : {
            'menu_id' : 4,
            'sub_menu_id' : 0,
            'nama' : 'pemesanan_obat',
            'deskripsi' : 'Pemesanan Obat',
            'status' : false,
        },
        'penjualan_obat' : {
            'menu_id' : 4,
            'sub_menu_id' : 0,
            'nama' : 'penjualan_obat',
            'deskripsi' : 'Penjualan Obat',
            'status' : false,
        },
        'penjualan_langsung' : {
            'menu_id' : 4,
            'sub_menu_id' : 0,
            'nama' : 'penjualan_langsung',
            'deskripsi' : 'Penjualan Langsung',
            'status' : false,
        },
        'stok_produk' : {
            'menu_id' : 4,
            'sub_menu_id' : 0,
            'nama' : 'stok_produk',
            'deskripsi' : 'Stok Produk',
            'status' : false,
        },
        'stok_opname' : {
            'menu_id' : 4,
            'sub_menu_id' : 0,
            'nama' : 'stok_opname',
            'deskripsi' : 'Stok Opname',
            'status' : false,
        },
        'kartu_stok' : {
            'menu_id' : 4,
            'sub_menu_id' : 0,
            'nama' : 'kartu_stok',
            'deskripsi' : 'Kartu Stok',
            'status' : false,
        },
        'data_supplier' : {
            'menu_id' : 4,
            'sub_menu_id' : 0,
            'nama' : 'data_supplier',
            'deskripsi' : 'Data Supplier',
            'status' : false,
        },
        'data_pelanggan' : {
            'menu_id' : 4,
            'sub_menu_id' : 0,
            'nama' : 'data_pelanggan',
            'deskripsi' : 'Data Pelanggan',
            'status' : false,
        },
        'racikan_obat' : {
            'menu_id' : 4,
            'sub_menu_id' : 0,
            'nama' : 'racikan_obat',
            'deskripsi' : 'Racikan Obat',
            'status' : false,
        },
        'lokasi_obat' : {
            'menu_id' : 4,
            'sub_menu_id' : 0,
            'nama' : 'lokasi_obat',
            'deskripsi' : 'Lokasi Obat',
            'status' : false,
        },
    },
    'akuntansi' : {
        'data_akun_keuangan' : {
            'menu_id' : 5,
            'sub_menu_id' : 0,
            'nama' : 'data_akun_keuangan',
            'deskripsi' : 'Data Akun Keuangan',
            'status' : false,
        },
        'data_jurnal_keuangan' : {
            'menu_id' : 5,
            'sub_menu_id' : 0,
            'nama' : 'data_jurnal_keuangan',
            'deskripsi' : 'Data Jurnal Keuangan',
            'status' : false,
        },
        'data_jurnal_singkat' : {
            'menu_id' : 5,
            'sub_menu_id' : 0,
            'nama' : 'data_jurnal_singkat',
            'deskripsi' : 'Data Jurnal Singkat',
            'status' : false,
        },
        'data_budgeting_akun_keuangan' : {
            'menu_id' : 5,
            'sub_menu_id' : 0,
            'nama' : 'data_budgeting_akun_keuangan',
            'deskripsi' : 'Data Budgeting Akun Keuangan',
            'status' : false,
        },
    },
    'hrd' : {
        'data_karyawan' : {
            'menu_id' : 6,
            'sub_menu_id' : 0,
            'nama' : 'data_karyawan',
            'deskripsi' : 'Data Karyawan',
            'status' : false,
        },
        'data_absensi_karyawan' : {
            'menu_id' : 6,
            'sub_menu_id' : 0,
            'nama' : 'data_absensi_karyawan',
            'deskripsi' : 'Data Absensi Karyawan',
            'status' : false,
        },
    },
    'pelayanan' : {
        'resepsionis' : {
            'menu_id' : 7,
            'sub_menu_id' : 0,
            'nama' : 'resepsionis',
            'deskripsi' : 'Resepsionis',
            'status' : false,
        },
        'perawatan_dokter' : {
            'menu_id' : 7,
            'sub_menu_id' : 0,
            'nama' : 'perawatan_dokter',
            'deskripsi' : 'Perawatan Dokter',
            'status' : false,
        },
        'farmasi' : {
            'menu_id' : 7,
            'sub_menu_id' : 0,
            'nama' : 'farmasi',
            'deskripsi' : 'Farmasi',
            'status' : false,
        },
        'farmasi_penjualan_langsung' : {
            'menu_id' : 7,
            'sub_menu_id' : 0,
            'nama' : 'farmasi_penjualan_langsung',
            'deskripsi' : 'Farmasi',
            'status' : false,
        },
        'kasir' : {
            'menu_id' : 7,
            'sub_menu_id' : 0,
            'nama' : 'kasir',
            'deskripsi' : 'Kasir',
            'status' : false,
        },
        'data_transaksi_perawatan' : {
            'menu_id' : 7,
            'sub_menu_id' : 0,
            'nama' : 'data_transaksi_perawatan',
            'deskripsi' : 'Data Transaksi Perawatan',
            'status' : false,
        },
    },
    'laporan_laporan' : {
        'laporan_pelayanan_klinik' : {
            'menu_id' : 8,
            'sub_menu_id' : 0,
            'nama' : 'laporan_pelayanan_klinik',
            'deskripsi' : 'Laporan Pelayana Klinik',
            'status' : false,
        },
        'laporan_rekam_medis_pasien' : {
            'menu_id' : 8,
            'sub_menu_id' : 0,
            'nama' : 'laporan_rekam_medis_pasien',
            'deskripsi' : 'Laporan Rekam Medis Pasien',
            'status' : false,
        },
        'laporan_farmasi' : {
            'menu_id' : 8,
            'sub_menu_id' : 0,
            'nama' : 'laporan_farmasi',
            'deskripsi' : 'Laporan Farmasi',
            'status' : false,
        },
        'laporan_akuntansi' : {
            'menu_id' : 8,
            'sub_menu_id' : 0,
            'nama' : 'laporan_akuntansi',
            'deskripsi' : 'Laporan Akuntansi',
            'status' : false,
        },
        'laporan_hrd' : {
            'menu_id' : 8,
            'sub_menu_id' : 0,
            'nama' : 'laporan_hrd',
            'deskripsi' : 'Laporan HRD',
            'status' : false,
        },
        'laporan_jasa_medis' : {
            'menu_id' : 8,
            'sub_menu_id' : 0,
            'nama' : 'laporan_jasa_medis',
            'deskripsi' : 'Lapran Jasa Medis',
            'status' : false,
        },
    },
    'analisis_statistik' : {
        'traffic' : {
            'menu_id' : 9,
            'sub_menu_id' : 0,
            'nama' : 'traffic',
            'deskripsi' : 'Traffic',
            'status' : false,
        },
        'revenue' : {
            'menu_id' : 9,
            'sub_menu_id' : 0,
            'nama' : 'revenue',
            'deskripsi' : 'Revenue',
            'status' : false,
        },
        'demografi' : {
            'menu_id' : 9,
            'sub_menu_id' : 0,
            'nama' : 'demografi',
            'deskripsi' : 'Demografi',
            'status' : false,
        },
    }
}
